import React from "react"
import { ActivityBox, DiscussBox, CopyrightBox } from "../../../components/boxes"
import { BreadCrumbs, Figure, Page, SmallSectionHeader, WhereNext } from "../../../components/page"
import Layout from "../../../layout"
import * as theme from "../../../theme"
import headerImage from "../section-header.jpg"
import worksheets from "../worksheets.svg"
import { withPrefix } from "gatsby"
import { StaticImage } from 'gatsby-plugin-image'

const Glass = () => (
  <Layout title="How is glass made?">
    <SmallSectionHeader
      color={theme.colors.yellow.dark}
      image={headerImage}
      to="/how-did-they-build-that"
    >
      How did they build that?
    </SmallSectionHeader>
    <BreadCrumbs items={[
      { to: '/how-did-they-build-that', name: 'How did they build that?' },
      { name: 'How is glass made?' }
    ]} />
    <Page>
      <h1>How is glass made?</h1>
      <p>
        Glass is made of sand, the ashes of plants and a chemical called calcium oxide or ‘lime’. This mixture is then heated to 1000 degrees in a furnace (an enclosed oven-like structure that allows material to be heated to very high temperatures). This allows the mixture to melt and whilst it is a liquid, different chemicals can be added to make glass of different colours. It takes up to four days for glass to strengthen and harden enough to use.
      </p>

      <p className="page-left">
        Today most sheet glass is made by machine but in the medieval period all glass had to be made by hand. There were two main ways that glass was made.
      </p>
      <Figure
        className="page-right row-3"
      >
        <StaticImage
          src="./glass-blowers.jpg"
          alt="Glass Blowers"
        />
      </Figure>
      <p className="page-left">
        The ‘muff’ method gathered a lump of molten glass on the end of a hollow tube. A tube was put into the molten glass and a long glass ‘balloon’ was blown. Whilst the glass was cooling it was split along its length and flattened out.
      </p>
      <p className="page-left">
        The ‘crown’ method blew a lump of molten glass into a ball. A hole was made in the ball, and the glass was then spun so the hole got bigger and the glass became a large disc shape.
      </p>

      <DiscussBox>
        Is the process of making glass a reversible or irreversible reaction?
      </DiscussBox>

      <h2>Creating the colours</h2>
      <div className="page-left row-2">
        <p>
          The medieval glass makers discovered that adding different metal oxides to the sand and other glass ingredients produced different colours of glass. For example, adding copper produced red glass, cobalt made a beautiful blue glass, manganese made pink or purple and iron oxides could be used for greens and yellows. However, it soon became clear that red (ruby) glass made this way was too dark for windows as not enough light could shine through to light up the glass and the building.
        </p>
        <p>
          The glass makers were extremely clever and decided to experiment with different ways of adding colour to the glass. To make a more transparent (lets more light pass through it) red, it was discovered that molten (liquid) white glass could be coated or ‘flashed’ by dipping it into molten red glass before it was made into flat glass for a window.
        </p>
        <p>
          In the centuries that followed, new techniques allowed glass artists an even wider range of colours. In the fourteenth century it was discovered that a chemical called silver nitrate could be painted on coloured glass and the glass heated to create different hues (colours or shades). This technique known as yellow or silver staining allowed different shades to be shown on the same piece of glass. In the sixteenth century enamel paints allowed many more colours to be used on the same piece of glass. Many of these methods are still in use today.
        </p>
      </div>

      <Figure
        caption="Materials from the Stained Glass Loanbox available at the Cathedral."
        className="page-right"
      >
        <StaticImage
          src="./loanbox.jpg"
          alt="Loanbox contents"
        />
      </Figure>

      <DiscussBox>
        Do you think glass was an expensive product in the middle ages? Why?
      </DiscussBox>

      <h2>Activities</h2>
      <ActivityBox
        title="How Did They Build That activity sheets"
        image={
          <img src={worksheets} alt="Worksheets" />
        }
        link={withPrefix('/HowDidTheyBuildThat.pdf')}
      >
        Click here to find a programme of curriculum linked classroom activities and resources to support the ‘How did they build that ’ resource.
      </ActivityBox>

      <WhereNext
        items={[
          { name: "Architecture", to: "/how-did-they-build-that/building-the-cathedral" },
          { name: "Stone", to: "/how-did-they-build-that/stone" },
          {
            name: "Stained Glass",
            to: "/how-did-they-build-that/stained-glass",
          },
        ]}
      />

      <hr />

      <CopyrightBox />

    </Page>
  </Layout>
)

export default Glass